export default{
	name: "datatable",
	props: {
		heroes: Array,
		columns: {
			type: [Object,Array],
		},
		action: Function,
		destroy: Function,
		modify: Function,
	},
	data() {
		return {
			sortKey: "",
			sortOrders: this.init_table(),
			paginate:{
				start: 1,
				end: this.heroes.length,
				limit: 50,
				page: 1,
			},
			limit_custom: 50,
			search_value: '',
			dataItem: this.heroes
		}
	},
	computed: {
		filteredHeroes() {
			var sortKey = this.sortKey;
			var search_value = this.search_value && this.search_value.toLowerCase();
			var order = this.sortOrders[sortKey];
			var dataItem = this.dataItem;
			if (search_value) {
				dataItem = this.heroes.filter(function(row) {
					return Object.keys(row).some(function(key) {
						return (
							String(row[key])
								.toLowerCase()
								.indexOf(search_value) > -1
						);
					});
				});
			}
			if (sortKey) {
				dataItem = dataItem.slice().sort(function(a, b) {
					a = a[sortKey];
					b = b[sortKey];
					return (a === b ? 0 : a > b ? 1 : -1) * order;
				});
			}
			return dataItem;
		}
	},
	filters: {
		capitalize(str) {
			let entry = str.charAt(0).toUpperCase() + str.slice(1);
			entry = entry.replace(/_/g," ")
			return entry
		}
	},
	methods: {
		init_table(){
			var sortOrders = {}
			if(Object.keys(this.columns).length>0)
				this.columns.forEach(function (key) {
					sortOrders[key] = 1
				})
			return sortOrders
		},
		sortBy(key) {
			this.sortKey = key;
			if(isNaN(this.sortOrders[key]))
				this.sortOrders[key] = 1
			else
				this.sortOrders[key] *= -1;
		},
		checkFunction(function_val){
			if(typeof(function_val)=="function")
				return true
			return false
		},

		PAGINATE_TABLE(){
			let limit = (this.limit_custom=="") ? this.paginate.limit:this.limit_custom
			this.dataItem = this.heroes.slice(this.paginate.start-1,limit+this.paginate.start-1)
			this.search_value=""
		},
		PAGINATE_COMPRESS(){
			return (this.paginate.end!=0) ? Math.ceil(this.paginate.end/this.paginate.limit) : 1
		},
		PAGINATE_NEXT(){
			if((this.paginate.start + this.limit_custom)<this.paginate.end){
				this.paginate.start += this.limit_custom
				this.PAGINATE_TABLE()
			}
		},
		PAGINATE_PREV(){
			if((this.paginate.start - this.limit_custom)>1){
				this.paginate.start -= this.limit_custom
				this.PAGINATE_TABLE()
			}
		},
	},
	mounted(){
		this.PAGINATE_TABLE()
	},
	watch:{
		heroes(){
			this.PAGINATE_TABLE()
		}
	}
}