import store from '../store'

describe('mutations', () => {

  const state = {
    histories: [],
    export_data: [],
  }
  
  it('renders index.msg when passed', () => {

    const histories = [{
      "id": 1,
      "numero_piece": "null",
      "piece": "KWN_EX_20191zqn.txt",
      "chemin": "file/operations/GfwLAHesudlDiIo15Vi4Zf4Ly7ycTAaN76nehVoH.txt",
      "commentaire": null,
      "created_at": "2020-11-10 17:09:30",
      "updated_at": "2020-11-10 17:09:30"
    }]

    store.mutations.SET_HISTORY(state, histories)
    expect(state.histories).toBe(histories)
  })
  
  it('renders index.msg when passed', () => {

    const export_data = [
      {
        "Code journal": "401RES",
        "Date operation": "2-1-2018",
        "Libelle compte": "RESTAURANT / REPAS / NOURRITURE",
        "Type operation": "Journal d'achat",
        "Libelle operation": "NATURALIA",
        "Montant": 16.64,
        "Nom société": "societe3",
        "Libelle exercice comptable": null,
        "exercice comptable": "2018-01-01 / 2018-12-31"
      },
      {
        "Code journal": "625710",
        "Date operation": "2-1-2018",
        "Libelle compte": "Restaurant",
        "Type operation": "Journal d'achat",
        "Libelle operation": "NATURALIA",
        "Montant": 16.64,
        "Nom société": "societe3",
        "Libelle exercice comptable": null,
        "exercice comptable": "2018-01-01 / 2018-12-31"
      },
    ]

    store.mutations.SET_EXPORT_DATA(state, export_data)
    expect(state.export_data).toBe(export_data)
  })
  
})

