
import datatable from "@/components/datatable/index.vue"

import {mapGetters} from 'vuex'

export default {
	name: 'Users',
	props:{
		// societe: [String,Number],
		exercice_comptable: [String, Number],
		date_cloture: String,
	},
	data() {
		return {
			table_headers: ''
		};
	},
	components:{
		datatable
	},
	methods: {
		delete_user(){
			
		},
		edit_user(){
			this.$router.push({name: 'EditUser',params:{id:1}})
		},
		view_user(){
			this.$router.push({name: 'ShowUser',params:{id:1}})
		},
	},
	computed: mapGetters({users:"settingStoreIndex/USER"}),
	created(){
		this.$store.dispatch("settingStoreIndex/USER",localStorage.getItem('id_societe'))
	},
	watch:{
		users(){
			if(this.users.length>0)
				this.table_headers = Object.keys(this.users[0])
		},
	}
}