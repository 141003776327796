import { mount } from '@vue/test-utils'
import Blog_view from '../views/show.vue'
import Blog from '../views/index.vue'

describe('-----------------  Blog  -------------------------', () => {

  test('Open blog', () => {
  const wrapper = mount(Blog,{
		stubs: ['router-link']
	 });
  expect(wrapper.vm).toBeTruthy()
  })

  test('show content of a blog page', () => {
    const wrapper = mount(Blog_view);
    expect(wrapper.vm).toBeTruthy()
  })
  
})