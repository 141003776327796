<template>
	<div id="app">
		<router-view></router-view>

		<notification v-for="popupdata in popups"
			:key="popupdata.id"
			:popup="popupdata"
		/>
	</div>
</template>

<style>
	*{
		font-family: "Open sans",sans-serif;
	}
	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/*text-align: center;*/
		color: #2c3e50;
		background-color: #F6F6F6;
	}
	#custom-card .card{
	}
	#custom-card .card-header{
		border: 1px solid rgba(0, 0, 0, .05);
		background: #fff;
	}
	#custom-card .card-header h3{
		font-size: 1.0625rem !important;
		font-weight: bold;
	}
	#custom-card .card-header .btn-sm{
		background: #5e72e4;
	}
	#custom-card .card-body{
		background: #f7fafc;
	}

</style>

<script type="text/javascript">
	
	import notification from '@/modules/notification/views/notification'
	
	import {mapGetters} from 'vuex'

	export default{
		data(){
			return {
				events: ['click','mousemove','mousedown','scroll','keypress','load'],
				warningTimer: null,
				logoutTimer: null,
				warningZone: false,
			}
		},
		components:{
			notification,
		},
		computed: {
			...mapGetters({
				popups : "notificationStoreIndex/POPUPS",
			}
		)},
		mounted(){
			this.events.forEach(function (event){
				window.addEventListener(event, this.resetTimer);
			},this)

			this.setTimers()
		},
		destroyed(){
			this.events.forEach(function (event){
				window.removeEventListener(event,this.resetTimer)
			},this)

			this.resetTimer()
		},
		methods:{
			setTimers(){
				this.warningTimer = setTimeout(this.warningMessage, 16*60*1000)
				this.logoutTimer = setTimeout(this.logoutUser, 32*60*1000)

				this.warningZone = false
			},
			warningMessage(){
				this.$store.dispatch('notificationStoreIndex/addNotification', {
					type: 'warning',
					message: "En absence de réactivité, vous allez etre deconnecté dans 3 minutes"
				}, { root: true });
			},
			async logoutUser(){
				await this.$store.dispatch("authStoreIndex/LOGOUT")
				await this.$router.push({name: "Home"})
			},
			resetTimer(){
				clearTimeout(this.warningTimer)
				clearTimeout(this.logoutTimer)

				this.setTimers()
			}
		}
	}
</script>