import store from '../store'
import {getJsonArray} from '@/components/utils/utils.js'

describe('mutations', () => {

  const state = {
    series: [],
    gauge: [],
    graph_1: [],
    graph_2: [],
    graph_3: [],
    graph_4: [],
    graph_5: [],
    graph_6: [],
    graph_7: [],
    graph_8: [],
    graph_9: [],
    graph_10: [],
    tresorerie: [],
    top: [],
    analyse: [],
  }
  
  it('renders index.msg when passed', () => {

    const gauge = [
      {"ca":[0,242093.39000000007],"charge":[0,60178.680000000022],"tresorerie":[0,489604.69999999984],"dettef":[0,0],"encour_clt":[0,0],"cpte_courant":[0,0],"solde_tva":[0,-5136.5699999999906]}
    ]

    store.mutations.SET_GAUGE(state, gauge)
    expect(state.gauge).toBe(gauge)
  })
  
  it('renders index.msg when passed', () => {

    const data_graph = [
      {"name":"previsionnel","data":[]},{"name":"r\u00e9el","data":{"1-2018":3971.8299999999999,"2-2018":7285.6499999999996,"3-2018":5312.0100000000002,"4-2018":9300.8299999999999,"5-2018":16643.02,"6-2018":26503.660000000003,"7-2018":20943.330000000002,"8-2018":25107.370000000003,"9-2018":33092,"10-2018":18383.029999999999,"11-2018":21108.029999999999,"12-2018":54442.629999999997}},{"name":"Budget\u00e9","data":[]}
    ]

    const receive = [{"name":"previsionnel","data":[{"1-2018":0,"2-2018":0,"3-2018":0,"4-2018":0,"5-2018":0,"6-2018":0,"7-2018":0,"8-2018":0,"9-2018":0,"10-2018":0,"11-2018":0,"12-2018":0}]},{"name":"réel","data":[{"1-2018":3971.83,"2-2018":7285.65,"3-2018":5312.01,"4-2018":9300.83,"5-2018":16643.02,"6-2018":26503.660000000003,"7-2018":20943.33,"8-2018":25107.370000000003,"9-2018":33092,"10-2018":18383.03,"11-2018":21108.03,"12-2018":54442.63}]},{"name":"Budgeté","data":[{"1-2018":0,"2-2018":0,"3-2018":0,"4-2018":0,"5-2018":0,"6-2018":0,"7-2018":0,"8-2018":0,"9-2018":0,"10-2018":0,"11-2018":0,"12-2018":0}]}]

    store.mutations.SET_DATA_GRAPH_1(state, getJsonArray(data_graph))
    expect(state.graph_1).toMatchObject(receive)

    store.mutations.SET_DATA_GRAPH_2(state, getJsonArray(data_graph))
    expect(state.graph_2).toMatchObject(receive)

    store.mutations.SET_DATA_GRAPH_3(state, getJsonArray(data_graph))
    expect(state.graph_3).toMatchObject(receive)

  })
  
})

