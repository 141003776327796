
import {mapGetters} from 'vuex'

export default {
	name: 'Dashboard',
	data() {
		return {
			import: {},
		};
	},

	methods: {
		importExcel(e) {
			const files = e.target.files;

			this.$store.commit("settingStoreIndex/SET_FILE",files[0])
			this.import.files = files[0]

			if (!files.length) {
				return ;
			} else if (!/\.(xls|xlsx|txt)$/.test(files[0].name.toLowerCase())) {
				this.$store.dispatch('notificationStoreIndex/addNotification', {
					type: 'warning',
					message: "The upload format is incorrect. Please upload xls or xlsx format"
				}, { root: true })
				return ;
			}
		},
		async IMPORT(){
			if(this.import.files == undefined){
				this.$store.dispatch('notificationStoreIndex/addNotification', {
					type: 'warning',
					message: "Aucun fichier ou document n'a été selectionné."
				}, { root: true })
				return ;
			}
			this.import.id_societe=localStorage.getItem("id_societe")

			this.import.id_scenario=1

			await this.$store.dispatch("settingStoreIndex/IMPORT_DATA",this.import)
		},
		display(){
			this.$router.push({name:'settingStoreIndex/History'})
		}
	},
	computed: mapGetters({
		import_process: "settingStoreIndex/IMPORT_PROCESS"
	}),
}