<template>
	<div id="blog" class="container">
		<div class="col-md-12 section_bg_gray">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="ptb-50">
							<h2 class="text-center">Dernieres actualités</h2>
						</div>
					</div>
				</div>
				<div class="row">
	
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3" v-for="el in 9" :key='el'>
						<div class="single_service">
							<div class="service_figure">
								<router-link :to="{name:'ShowBlog', params:{id:el}}"><img src="https://cdn.pixabay.com/photo/2016/11/19/21/01/analysis-1841158__480.jpg" class="img-fluid"></router-link>
							</div>
							<h4 class="service_title">Accompagnement d'un expert comptable physique</h4>
							<div class="service_content">Nous ne sommes pas un cabinet en ligne. Vous avez un accès directemznt à  l'expert comptable</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>

	</div>
</template>

<script>

export default {
	name: 'Home',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	#blog .jumbotron{
		color: #fafafa;
		height: 100%;
		padding-top: 160px;
		background-image: url("https://cdn.pixabay.com/photo/2015/03/27/20/42/smartphone-695164__480.jpg");
		background-repeat: no-repeat;
		background-size: cover;
	}
	#blog .jumbotron .jumbotron-title{
		font-weight: bold;
		font-size: 24pt;
		text-align: center;
	}
	.container-carousel .row [class*='col-md-']{
		padding-left:0;
		padding-right:0;
	}

	.ptb-50{
		padding: 50px 50px 10px 50px;
	}

	.ptb-50 h2{
		color: #dc393a;
		font-weight: bold;
		font-size: 30pt;
	}

	.section_bg_gray {
		background-color: bold;
		padding-bottom: 50px;
	}

	.single_service {
		background-color: #fafafa;
		text-align: center;
		width: 90%;
		padding: 30px 30px;
		border-radius: 10px;
		position: relative;
		box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
	}

	.single_service .service_figure {
		/*padding: 40px 0;*/
	}
	.single_service .service_figure img {
		margin: 0 auto;
		padding-bottom: 30px;
		width: 100%;
		max-height: 150px;
	}
	.single_service .service_title {
		font-weight: 600;
		font-size: 19px;
		padding-bottom: 25px;
		color: #555555;
		margin: 0;
	}

</style>
