<template>
	<div class="container">

		<form @submit.prevent="LOGIN_FORM" class="jumbotron login-form mb-5">
			<h1>Page de connection</h1>

			<div class="txtb">
				<input type="text" placeholder="Adresse electronique" v-model="login.email" :class="{ 'is-invalid': submitted && $v.login.email.$error }">
				<div v-if="submitted && $v.login.email.$error">
					<span v-if="!$v.login.email.required">This field is required</span>
					<span v-if="!$v.login.email.email">This field is invalid</span>
				</div>
			</div>
			
			<div class="txtb">
				<input type="password" placeholder="Mot de passe" v-model="login.password">
				<div v-if="submitted && $v.login.password.$error">
					<span v-if="!$v.login.password.required">This field is required</span>
					<span v-if="!$v.login.password.minLength">This field is short</span>
				</div>
			</div>
			<button type="submit" class="logbtn">Se connecter</button>

		</form>
	
	</div>
</template>

<script type="text/javascript">

	import { required, email, minLength } from "vuelidate/lib/validators";

	export default{
		name: "Auth",
		data(){
			return{
				login:{},
				submitted: false,
			}
		},
		methods:{
			async LOGIN_FORM(){
				this.submitted=true

				this.$v.$touch();
				if (this.$v.$invalid) {
					return;
				}else{				
					await this.$store.dispatch("authStoreIndex/LOGIN",this.login)

					await this.CHECK_TOKEN()
				}

			},
			CHECK_TOKEN(){
				setTimeout(function(){
					if(localStorage.getItem('token') != null || localStorage.getItem('token')!= undefined){
						this.$router.push({name: "Dashboard"})
					}
				}.bind(this), 3400)
			}
		},
		validations: {
			login: {
				email: { required, email },
				password: { required, minLength: minLength(6) },
			}
		},
	}

</script>

<style type="text/css" scoped="">
	.bg-color,.logbtn{
		background-color: #bc2e2e;
		color: #fafafa;
		font-size: 18pt;
		border-radius: 30px;
	}
	.login-form {
		height: auto;
		padding: 0px 50px 0px 50px;
		padding-bottom: 100px;
	}
	.login-form h1 {
		color: #9E9E9E;
		text-align: center;
	}

	.login-form .txtb {
		border-bottom: 2px solid #adadad;
		position: relative;
		margin: 30px 0;
	}

	.login-form .txtb span {
		color: #DA4439;
	}

	.login-form .txtb input {
		font-size: 15px;
		color: black;
		border: none;
		width: 100%;
		outline: none;
		background: none;
		padding: 0 5px;
		height: 40px;
	}
	.login-form .txtb input::placeholder {
		color: #9E9E9E;
		font-weight: bold;
	}

	.login-form .loglink{
		color: white;
		font-weight: bold;
		font-size: 15pt;
	}

	.jumbotron{
    background: none;
    color: white;
    height: 100%;
    padding-top: 20px;
    border-radius: 0;
  }
  .jumbotron .jumbotron-title{
    font-weight: bold;
    font-style: italic;
  }
  .jumbotron .jumbotron-content{
    font-size: 17pt;
  }
</style>