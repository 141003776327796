import Api from '@/components/API'

import router from "@/router"

const state = {
	societe: {},
	exercice_comptable: {},
	storage:[],
}

const	mutations = {
	SET_SOCIETE: (state, data)=>{
		state.societe = data
	},
	SET_EXERCICE_COMPTABLE: (state, data)=>{
		state.exercice_comptable = data
	},
	SET_STORAGE(state,data){
		state.storage[data[0]] = data[1]
	},
}

const getters = {
	SOCIETE: state => {
		return state.societe
	},
	EXERCICE_COMPTABLE: state => {
		return state.exercice_comptable
	},
	STORAGE: (state,key) => {
		return state.storage[key]
	},
}
const actions = {
	STORAGE: ({commit},element) => {
		commit("SET_STORAGE",element)
	},
	SOCIETE: ({commit,dispatch}) => {
		Api().get('societes')
		.then(response => {
			commit("SET_SOCIETE",response.data.data)
		})
		.catch(error=>{
			dispatch('CATCH_ERROR',error.response,"Probleme survenue lors de la recuperation.")
		})
	},
	EXERCICE_COMPTABLE: ({commit,dispatch},societe) => {
		Api().get('exercice_comptables/societe/'+societe).then(response => {
			commit("SET_EXERCICE_COMPTABLE",response.data.data)
		})
		.catch(error=>{
			dispatch('CATCH_ERROR',error.response,"Probleme survenue lors de la recuperation.")
		})
	},
	CATCH_ERROR: async({dispatch},error,messageError="Probleme survenue lors du traitement de votre opération.") => {
		switch(error.data.code){
			case 'AuthF':
				dispatch('notificationStoreIndex/addNotification', {
					type: 'danger',
					message: messageError || "Adresse electronique ou mot de passe érronnée. Veuillez réessayer"
				}, { root: true });
			break;

			default:
				dispatch('notificationStoreIndex/addNotification', {
					type: 'danger',
					message: messageError
				}, { root: true });
			break;
		}
		if(error.status==403){
			await localStorage.clear()
			await router.push({name: 'Login'})
		}
	},
}

export default {
	state,
	mutations,
	getters,
	actions
}