import Api from '@/components/API'

const state = {
	user: {},
	token: ""
}

const	mutations = {
	SET_USER: (state, data)=>{
		state.user = data.user.name
		state.token = data.access_token
	},
}

const getters = {
	USER: state => {
		return state.user
	},
	TOKEN: state => {
		return state.token
	},
}

const actions = {
	LOGIN: ({dispatch},user) => {
		const form = require('querystring')
		Api().post('login',form.stringify(user))
		.then(response => {
			localStorage.setItem("token",response.data.data.access_token)
			localStorage.setItem("user",JSON.stringify(response.data.data.user))

			dispatch('notificationStoreIndex/addNotification', {
				type: 'success',
				message: "L'authentification a été un success"
			}, { root: true });
		})
		.catch(function (error) {
			dispatch('defaultStoreIndex/CATCH_ERROR',error.response)
		})
	},
	LOGOUT: ({dispatch}) => {
		Api().put('logout')
		.then(function(){
			dispatch('notificationStoreIndex/addNotification', {
				type: 'success',
				message: "Vous etes a présent déconnecter."
			}, { root: true })

		})
		localStorage.clear()
	},
}

export default {
	state,
	mutations,
	getters,
	actions
}