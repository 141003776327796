
const Setting = () => import('@/modules/setting/views/index.vue')

const Import = () => import('@/modules/setting/views/import/index.vue')

const Display = () => import('@/modules/setting/views/display/comptable/index.vue')
const History = () => import('@/modules/setting/views/display/history/index.vue')

const User = () => import('@/modules/setting/views/user/index.vue')
const CreateUser = () => import('@/modules/setting/views/user/create/index.vue')
const ShowUser = () => import('@/modules/setting/views/user/show/index.vue')
const EditUser = () => import('@/modules/setting/views/user/edit/index.vue')

const UserGroup = () => import('@/modules/setting/views/group/index.vue')

const Societe = () => import('@/modules/setting/views/societe/index.vue')

const Default = () => import('@/components/aside/aside.vue')

const routes = [
	{
		path: '/app/setting',
		component: Default,
		meta:{
			requiresAuth: true,
			title: 'Parametrage',
			affix: true
		},
		children:[
			{
				path: '',
				name: 'Setting',
				component: Setting,
			},
			{
				path: 'import',
				name: 'Import',
				component: Import,
				meta: {
					title: 'Importation',
					affix: true
				},
			},
			{
				path: 'history',
				component: {
					render (c) { return c('router-view') }
				},
				meta: {
					title: 'Historique',
					affix: true
				},
				children:[
					{
						path: '',
						name: 'History',
						component: History,
					},
					{
						path: ':id',
						name: 'Display',
						component: Display,
						meta: {
							title: 'Consultation',
							affix: true
						},
					},
				]
			},
			{
				path: 'user',
				component: {
					render (c) { return c('router-view') }
				},
				meta: {
					title: 'Utilisateurs',
					affix: true
				},
				children:[
					{
						path: '',
						name: 'User',
						component: User,
					},
					{
						path: 'create',
						name: 'CreateUser',
						component: CreateUser,
						meta: {
							title: 'Creer un nouvel utilisateur',
							affix: true
						},
					},
					{
						path: ':id',
						name: 'ShowUser',
						component: ShowUser,
						meta: {
							title: 'Visualiser utilisateur',
							affix: true
						},
					},
					{
						path: ':id/edit',
						name: 'EditUser',
						component: EditUser,
						meta: {
							title: "Modifier les informations de l'utilisateur",
							affix: true
						},
					},
				]
			},
			{
				path: 'group',
				component: {
					render (c) { return c('router-view') }
				},
				meta: {
					title: 'Groupes utilisateurs',
					affix: true
				},
				children:[
					{
						path: '',
						name: 'UserGroup',
						component: UserGroup,
					},
				]
			},
			{
				path: 'societe',
				component: {
					render (c) { return c('router-view') }
				},
				meta: {
					title: 'Sociétés',
					affix: true
				},
				children:[
					{
						path: '',
						name: 'Societe',
						component: Societe,
					},
				]
			},
		]
	}
]

export default routes