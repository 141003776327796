<template>
	<div class="">
		<div class="card-body">
			<div class="form-group">
				<label>Nom</label>
				<input type="text" class="form-control" v-model="events.title">
			</div>
			<div class="form-group">
				<label>Date de debut</label>
				<div class="form-row">
					<input type="date" class="col-md-6 form-control" v-model="events.start">
					<input type="time" class="col-md-6 form-control" v-model="events.start_time" value="9:00">
				</div>
			</div>
			<div class="form-group">
				<label>Date de fin</label>
				<div class="form-row">
					<input type="date" class="col-md-6 form-control" v-model="events.end">
					<input type="time" class="col-md-6 form-control" v-model="events.end_time" value="18:00">
				</div>
			</div>
			<div class="form-group">
				<label>Definir une couleur</label>
				<input type="color" class="form-control" v-model="events.color">
			</div>
			<div class="row">
				<div class="col-md-6 form-group">
					<button type="button" class="btn btn-primary" @click="updateEvent">Confirmer la modification</button>
				</div>
				<div class="col-md-6 form-group">
					<button type="button" class="btn btn-danger" @click="deleteEvent">Confirmer la suppression</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import moment from 'moment'

export default {
	name: 'EventModal',
	data(){
		return{
			events:{
				color: "#3788d8",
				societe_id: localStorage.id_societe,
				id: this.event.id,
				allDay: false,
			}
		}
	},
	methods: {
		async updateEvent () {			
			this.events.start=moment(this.events.start+' '+this.events.start_time).format("YYYY-MM-DD HH:mm:ss")
			this.events.end=moment(this.events.end+' '+this.events.end_time).format("YYYY-MM-DD HH:mm:ss")
			this.$store.dispatch("agendaStoreIndex/UPDATE_EVENT", this.events)
			await this.$emit('close')
		},
		async deleteEvent () {
			this.$store.dispatch("agendaStoreIndex/DELETE_EVENT", this.event.id)
			await this.$emit('close')
		}
	},
	props: {
		text: String,
		event: Object
	},
	created() {
		this.events.title = this.event.title
		this.events.start = moment(this.event.start).format('YYYY-MM-DD')
		this.events.end = moment(this.event.end).format('YYYY-MM-DD')
		this.events.start_time = moment(this.event.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
		this.events.end_time = moment(this.event.end, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
		this.events.color = this.event.color || "#3788d8"
	}
}
</script>

<style>
</style>