
	import highchartsVue from '@/modules/dashboard/components/highchartsVue/index.vue'

	import Highcharts from "highcharts"
	import hcMore from "highcharts/highcharts-more"
	hcMore(Highcharts)

	import { mapGetters } from 'vuex'


	export default{
		
		name: "Dashboard",
		props:{
			societe: [String,Number],
			exercice_comptable: [String, Number],
			date_cloture: String,
		},
		components:{
			highchartsVue
		},
		computed: mapGetters(
			{
				series: "dashboardStoreIndex/SERIE",
				gauges: "dashboardStoreIndex/GAUGE",
				DATA_1: "dashboardStoreIndex/DATA_GRAPH_1",
				DATA_2: "dashboardStoreIndex/DATA_GRAPH_2",
				DATA_3: "dashboardStoreIndex/DATA_GRAPH_3",
				DATA_4: "dashboardStoreIndex/DATA_GRAPH_4",
				DATA_5: "dashboardStoreIndex/DATA_GRAPH_5",
				DATA_6: "dashboardStoreIndex/DATA_GRAPH_6",
				DATA_7: "dashboardStoreIndex/DATA_GRAPH_7",
				DATA_8: "dashboardStoreIndex/DATA_GRAPH_8",
				DATA_9: "dashboardStoreIndex/DATA_GRAPH_9",
				DATA_10: "dashboardStoreIndex/DATA_GRAPH_10",
				tresorerie: "dashboardStoreIndex/DATA_GRAPH_TRESORERIE",
				ANALYSE: "dashboardStoreIndex/DATA_GRAPH_ANALYSE",
				TOP: "dashboardStoreIndex/DATA_GRAPH_TOP",
			}
		),
		watch:{
			date_cloture(){
				this.$store.dispatch("dashboardStoreIndex/GET_DATA_GRAPH",{id_societe:this.societe,id_exercice_cp:this.exercice_comptable,cloture:this.date_cloture})
			}
		}
	}
