import { mount } from '@vue/test-utils'
import agenda from '../views/EventModal.vue'

describe('--------------------  HIGHCHART_GAUGE GAUGE  -----------------------', () => {

	describe('--------------------  HIGHCHART_GAUGE column  -----------------------', () => {
		
		it('Dashboard pie chart vue instance', async () => {
			const wrapper = mount(agenda,{
				propsData:{
					event:{
						title: "Enoncé",
						start: "2018-4-4",
						end: "2018-4-4",
					}
				}
			})

			expect(wrapper.vm).toBeTruthy()
		})
		
	})

})
