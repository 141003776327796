export function getJsonArray(series){

	var serie = JSON.parse(JSON.stringify(series))

	var tabMin = []
	var max = 0
	var i = 0

	for(i=0;i<serie.length;i++){
		if(serie[i].data.length==0)
			tabMin.push(i)
		else{
			serie[i].data=[serie[i].data]
			max = i
		}
	}

	for(i=0;i<tabMin.length;i++){
		var tab= {}
		for(var t in serie[max].data[0]){
			tab[t]=0
		}
		serie[tabMin[i]].data = [tab]
		tab={}
	}
	return serie
}

export function setToken(token){
	return localStorage.setItem("token",token)
}

export function getToken(){
	return localStorage.getItem("token")
}
