
const Dashboard = () => import('@/modules/dashboard/views/index.vue')

const Default = () => import('@/components/aside/aside.vue')

const routes = [
	{
		path: '/app/dashboard',
		component: Default,
		meta:{
			requiresAuth: true,
			title: "Dashboard"
		},
		children:[
			{
				path: '',
				name: 'Dashboard',
				component: Dashboard,
			}
		]
	}
]

export default routes