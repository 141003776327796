
import {mapGetters} from 'vuex'

import datatable from "@/components/datatable/index.vue"

export default {
	name: 'Display',
	components:{
		datatable
	},
	data() {
		return {
			table_headers: {},
			search_value: '',
		};
	},
	props:{
		societe: [String, Number],
		exercice_comptable: [String, Number],
		date_cloture: String
	},
	methods: {
		transform_datatable(){
			this.table_headers = Object.keys(this.history[0])
		},
		load_data(piece){
			this.$router.push({name: 'Display',params:{id:piece}})
		},
		remove_data(piece){
			this.$store.dispatch("settingStoreIndex/REMOVE_EXERCICE_COMPTABLE",piece)
		}
	},
	created(){
		this.$store.dispatch("settingStoreIndex/GET_HISTORY",localStorage.getItem("id_societe"))
	},
	computed: mapGetters({
		history: "settingStoreIndex/HISTORY",
	}),
	watch:{
		history(){
			if(this.history.length>0){
				this.transform_datatable()
			}
		},
		async societe(){
			await this.$store.dispatch("settingStoreIndex/GET_HISTORY",this.societe)
		}
	}
}