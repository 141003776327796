import Api from '@/components/API'
import moment from 'moment'
const state = {
	file: null,
	export_data: null,
	import_process: [],
	histories: [],

	users: [
		{
			"Groupe": "CAIRN",
			"Base": "CAIRN",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "02/24/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Dalloz",
			"Base": "Dalloz",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/01/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Num�rique Premium",
			"Base": "Numerique Premium",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "OpenEdition",
			"Base": "OpenEdition Journals",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/01/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Pers�e",
			"Base": "Pers�e",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "CAIRN",
			"Base": "CAIRN",
			"Composante": "Centre Relations Entreprises Formation Permanente",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/08/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Dawsonera",
			"Base": "Dawsonera",
			"Composante": "Centre Relations Entreprises Formation Permanente",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "OpenEdition",
			"Base": "OpenEdition Journals",
			"Composante": "Centre Relations Entreprises Formation Permanente",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/03/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "CAIRN",
			"Base": "CAIRN",
			"Composante": "Centre de ressources en langues",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/01/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Brepols",
			"Base": "Brepols",
			"Composante": "�cole doctorale Droit et Science Politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Cambridge University Press",
			"Base": "Cambridge University Press",
			"Composante": "�cole doctorale Droit et Science Politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Doctrinal",
			"Base": "Doctrinal Plus",
			"Composante": "�cole doctorale Droit et Science Politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/03/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "ELnet",
			"Base": "ELnet",
			"Composante": "�cole doctorale Droit et Science Politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "OpenEdition",
			"Base": "OpenEdition Books",
			"Composante": "Antenne d�localis�e de l'IUT � Nanterre",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Europresse",
			"Base": "Europresse",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/23/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Europresse",
			"Base": "Europresse nouvelle plateforme",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/24/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Harmath�que",
			"Base": "Harmath�que",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "OpenEdition",
			"Base": "OpenEdition Books",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/02/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Techniques de l'Ing�nieur",
			"Base": "Techniques de l'Ing�nieur",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Brill",
			"Base": "Brill",
			"Composante": "Droit et science politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/19/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Harmath�que",
			"Base": "Harmath�que",
			"Composante": "Droit et science politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/15/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Lexisnexis Jurisclasseur",
			"Base": "Lexisnexis Jurisclasseur",
			"Composante": "Droit et science politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "09/10/1901",
			"Ann�e": "2015-2016"
		}
	],

	user_groups: [
		{
			"Groupe": "CAIRN",
			"Base": "CAIRN",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "02/24/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Dalloz",
			"Base": "Dalloz",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/01/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Num�rique Premium",
			"Base": "Numerique Premium",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "OpenEdition",
			"Base": "OpenEdition Journals",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/01/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Pers�e",
			"Base": "Pers�e",
			"Composante": "Sciences et techniques des activit�s physiques et sportives",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "CAIRN",
			"Base": "CAIRN",
			"Composante": "Centre Relations Entreprises Formation Permanente",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/08/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Dawsonera",
			"Base": "Dawsonera",
			"Composante": "Centre Relations Entreprises Formation Permanente",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "OpenEdition",
			"Base": "OpenEdition Journals",
			"Composante": "Centre Relations Entreprises Formation Permanente",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/03/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "CAIRN",
			"Base": "CAIRN",
			"Composante": "Centre de ressources en langues",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/01/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Brepols",
			"Base": "Brepols",
			"Composante": "�cole doctorale Droit et Science Politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Cambridge University Press",
			"Base": "Cambridge University Press",
			"Composante": "�cole doctorale Droit et Science Politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Doctrinal",
			"Base": "Doctrinal Plus",
			"Composante": "�cole doctorale Droit et Science Politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/03/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "ELnet",
			"Base": "ELnet",
			"Composante": "�cole doctorale Droit et Science Politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "OpenEdition",
			"Base": "OpenEdition Books",
			"Composante": "Antenne d�localis�e de l'IUT � Nanterre",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Europresse",
			"Base": "Europresse",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/23/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Europresse",
			"Base": "Europresse nouvelle plateforme",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/24/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Harmath�que",
			"Base": "Harmath�que",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "OpenEdition",
			"Base": "OpenEdition Books",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/02/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Techniques de l'Ing�nieur",
			"Base": "Techniques de l'Ing�nieur",
			"Composante": "IUT de Ville-d'Avray",
			"Statut": "Licence",
			"Nombre_utilisateurs": "12/31/1899",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Brill",
			"Base": "Brill",
			"Composante": "Droit et science politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/19/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Harmath�que",
			"Base": "Harmath�que",
			"Composante": "Droit et science politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "01/15/1900",
			"Ann�e": "2015-2016"
		},
		{
			"Groupe": "Lexisnexis Jurisclasseur",
			"Base": "Lexisnexis Jurisclasseur",
			"Composante": "Droit et science politique",
			"Statut": "Licence",
			"Nombre_utilisateurs": "09/10/1901",
			"Ann�e": "2015-2016"
		}
	],

	societes: [],

}

const	mutations = {
	SET_FILE(state,payload){
		state.file = payload;
	},
	SET_EXPORT_DATA(state,payload){
		state.export_data = payload;
	},
	SET_HISTORY(state,payload){
		state.histories = payload;
	},
	SET_IMPORT_PROCESS(state,payload){
		state.import_process.push(payload);
	},
	UPDATE_IMPORT_PROCESS(state,payload){
		let position = state.import_process.findIndex(x=>x.start==payload.start)
		state.import_process[position] = payload
	},

	SET_USER(state,payload){
		state.users = payload
	},

	SET_USER_GROUPS(state,payload){
		state.user_groups = payload
	},

	SET_SOCIETE(state, payload){
		state.societes = payload
	}

}

const getters = {
	FILE: state =>{
		return state.file
	},
	EXPORT_DATA: state =>{
		return state.export_data
	},
	IMPORT_PROCESS: state =>{
		return state.import_process
	},
	HISTORY: state =>{
		return state.histories
	},

	USER: state =>{
		return state.users
	},

	USER_GROUPS: state =>{
		return state.user_groups
	},

	SOCIETE: state =>{
		return state.societes
	}

}

const actions = {
	IMPORT_DATA: async ({state,dispatch,commit},data)=>{
		if(data.id_societe<=0){
			dispatch('notificationStoreIndex/addNotification', {
				type: 'warning',
				message: "Vos devez au préalable selectionner une société"
			}, { root: true })
			return 0;
		}

		let formData = new FormData();
		formData.append("id_societe",data.id_societe)
		formData.append("id_scenario",1)
		formData.append("file_op",state.file)

		dispatch('notificationStoreIndex/addNotification', {
			type: 'loading',
			message: "Donnée en cours d'importation"
		}, { root: true })
		let tab = {};
		tab['societe'] = localStorage.getItem("name_societe")
		tab['name'] = data.files.name
		tab["start"] = moment().format('h:mm:ss')
		tab["time"] = ""
		tab["end"] = ""
		tab["state"] = 'load'

		commit("SET_IMPORT_PROCESS",tab)

		await Api().post("operations/",
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		)
		.then(function(){
			dispatch('notificationStoreIndex/addNotification', {
				type: 'success',
				message: "Vos données ont été importée avec success"
			}, { root: true })
			tab["state"]="success"
		})
		.catch(function(){
			dispatch('notificationStoreIndex/addNotification', {
				type: 'danger',
				message: "Probleme survenue lors de l'importation des données"
			}, { root: true })
			tab["state"]="cancel"
		})

		tab["end"] = moment().format('h:mm:ss')
		tab["time"] = Math.abs(moment.duration(tab["end"]).seconds()-moment.duration(tab['start']).seconds())

		commit("UPDATE_IMPORT_PROCESS",tab)
	},
	EXPORT_DATA: async({commit},data)=>{
		await Api().get('operations/piece_comptable/'+data)
		.then(response=>{
			commit("SET_EXPORT_DATA",response.data.data)
		})
	},
	GET_HISTORY: async({commit},data)=>{
		Api().get('societes/piece_comptable/'+data)
		.then(response=>{
			commit("SET_HISTORY",response.data.data)
		})
	},
	REMOVE_EXERCICE_COMPTABLE: async({dispatch},data)=>{
		await Api().delete('operations/piece_comptable/'+data)
		.then(()=>{
			dispatch('notificationStoreIndex/addNotification', {
				type: 'success',
				message: "L'exercice associé a été supprimé avec success"
			}, { root: true })
		})
		.catch(function (error){
			dispatch('defaultStoreIndex/CATCH_ERROR',error.response,"Probleme survenue lors de la suppressions des donnée lié a la piece comptable.")
		})
		await dispatch("GET_HISTORY",localStorage.getItem("id_societe"))
	},

	USER_GROUPS: async ({commit})=>{
		await Api().get('/group_users')
		.then(response=>{
			commit('SET_USER_GROUPS',response.data.data)
		})
	},

	SOCIETE: async ({commit})=>{
		await Api().get('/societes')
		.then(response=>{
			commit('SET_SOCIETE', response.data.data)
		})
	},

	USER: async ({commit},societe)=>{
		await Api().get('user/societe/'+societe)
		.then(response=>{
			commit('SET_USER',response.data.data)
		})
	},

}

export default {
	state,
	mutations,
	getters,
	actions
}