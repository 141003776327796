
import {mapGetters} from 'vuex'

import datatable from "@/components/datatable/index.vue"

export default {
	name: 'Display',
	components:{
		datatable
	},
	data() {
		return {
			table_headers: {},
			search_value: '',
		};
	},

	methods: {
		transform_datatable(){
			this.table_headers = Object.keys(this.export_datatable[0])
		},
	},
	async created(){
		if(localStorage.getItem("id_exercice")==null || localStorage.getItem("id_exercice")==undefined)
			await this.$router.push({name: 'History'})
		else
			await this.$store.dispatch("settingStoreIndex/EXPORT_DATA",this.$route.params.id)
	},
	computed: mapGetters({
		export_datatable: "settingStoreIndex/EXPORT_DATA"
	}),
	watch:{
		export_datatable(){
			if(this.export_datatable.length>0){
				this.transform_datatable()
			}
		}
	}
}