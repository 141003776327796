
const Agenda = () => import('@/modules/agenda/views/index.vue')

const Default = () => import('@/components/aside/aside.vue')

const routes = [
	{
		path: '/app/agenda',
		component: Default,
		meta:{
			requiresAuth: true,
			title: "Agenda"
		},
		children:[
			{
				path: '',
				name: 'Agenda',
				component: Agenda,
			},
		]
	}
]

export default routes