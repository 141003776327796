import Vue from 'vue'
import App from './App.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery'
import 'jquery/dist/jquery.min.js'

import router from '@/router'
import store from '@/store'

import Vuelidate from 'vuelidate'

import HighchartsVue from 'highcharts-vue';

import modal from "vue-js-modal";

Vue.use(modal, { dialog: true, dynamic: true });

Vue.use(Vuelidate)

Vue.use(HighchartsVue)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
