import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Home = () => import('@/views/index.vue')

const Dash = () => import('@/views/dash.vue')

const Contact = () => import('@/views/contact.vue')

const Policy = () => import('@/views/policy.vue')

const About = () => import('@/views/about-us.vue')

const CreateEntreprise = () => import('@/views/create_entreprise.vue')

const Paie = () => import('@/views/paie.vue')

const defaultroutes = [
	{
		path: '/',
		component: Dash,
		children:[
			{
				path: '',
				name: "Home",
				component: Home,
				meta: {
					title: 'Home',
					affix: true
				},
			},
			{
				path: 'contactez-nous',
				name: "Contact",
				component: Contact,
				meta: {
					title: 'Contact',
					affix: true
				},
			},
			{
				path: 'politique-confidentialité',
				name: "Policy",
				component: Policy,
				meta: {
					title: 'Policy',
					affix: true
				},
			},
			{
				path: 'about-us',
				name: "About",
				component: About,
				meta: {
					title: 'About',
					affix: true
				},
			},
			{
				path: 'creation-entreprise',
				name: "CreateEntreprise",
				component: CreateEntreprise,
			},
			{
				path: 'paie',
				name: "Paie",
				component: Paie,
			},
		]
	},
]



var allRoutes = []
// import camelCase from 'lodash/camelCase'
const requireModule = require.context('@/modules', true, /\.js$/)
const importedRoutes = []

requireModule.keys().forEach(fileName => {
	let str = fileName.split('/')
	str = str[1]
	if (fileName === `./${str}/router/index.js`){
		// const moduleName = camelCase(
		// 	fileName.replace(/(\.\/|\.js)/g, '')
		// )
		// console.log(moduleName)
		importedRoutes.push(...requireModule(fileName).default)
	}
})

/**
 * CONCAT ALL THE IMPORTED ROUTES WITH MAIN ROUTES...
 */
allRoutes = allRoutes.concat(defaultroutes,importedRoutes)
const routes = allRoutes

let router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to,from,next)=>{
	if(to.matched.some(record=>record.meta.requiresAuth==true)){
		if(localStorage.getItem("token") == null || localStorage.getItem('token') == ""){
			next({name: "Home"})
		}else{
			next()
		}
	}
	else{
		if((["Login","Register"].includes(to.name)) && localStorage.getItem("token") != null){
			next({name: "Dashboard"})
		}
		next()
	}
})

export default router