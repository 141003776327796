const state = {
	notifications: [],
	popups: [],
}

const	mutations = {
	PUSH_NOTIFICATION:(state, notification) => {
		state.notifications.push({
			...notification,
		})
		state.popups.push({
			...notification,
			id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
		})
	},	
	REMOVE_POPUP:(state, popupToRemove) => {
			state.popups = state.popups.filter(popup => {
				return popup.id != popupToRemove.id;
		})
	}
}

const getters = {
	NOTIFICATIONS: state =>{
		return state.notifications.reverse()
	},
	POPUPS: state =>{
		return state.popups
	}
}

const actions = {
	addNotification : ({ commit }, notification) => {
		commit('PUSH_NOTIFICATION', notification);
	},
	removePopup : ({ commit }, popup) => {
		commit('REMOVE_POPUP', popup);
	}
}

export default {
	state,
	mutations,
	getters,
	actions
}