import Api from '@/components/API'

import {getJsonArray} from '@/components/utils/utils.js'

function ca_charge(temp1,temp2,key="réel",key_2=null){
	var tre1 = JSON.parse(JSON.stringify(temp1))
	var tre2 = JSON.parse(JSON.stringify(temp2))
	let lda = []
	for(var i=0;i<tre1.length;i++){
		if(tre1[i].name==key && key_2==null){
			tre1[i].name="Chiffre affaire "+key
			lda.push(tre1[i])
			break;
		}else if(tre1[i].name==key && key_2!=null){
			tre1[i].name="Chiffre affaire "+key_2
			lda.push(tre1[i])
			break;
		}
	}
	
	for(var j=0;j<tre2.length;j++){
		if(tre2[j].name==key && key_2==null){
			tre2[j].name="Charge "+key
			lda.push(tre2[i])
			break;
		}
		else if(tre2[j].name==key && key_2!=null){
			tre2[j].name="Charge "+key_2
			lda.push(tre2[i])
			break;

		}
	}

	return lda
}

const getDefaultState = () => {
	return {
		series: [],
		gauge: [],
		graph_1: [],
		graph_2: [],
		graph_3: [],
		graph_4: [],
		graph_5: [],
		graph_6: [],
		graph_7: [],
		graph_8: [],
		graph_9: [],
		graph_10: [],
		tresorerie: [],
		top: [],
		analyse: [],
	}
}
const state = {
	series: [],
	gauge: [],
	graph_1: [],
	graph_2: [],
	graph_3: [],
	graph_4: [],
	graph_5: [],
	graph_6: [],
	graph_7: [],
	graph_8: [],
	graph_9: [],
	graph_10: [],
	tresorerie: [],
	top: [],
	analyse: [],
}

const	mutations = {
	RESET_STATE(state){
		Object.assign(state,getDefaultState())
	},
	SET_CATEGORIE: (state, categorie)=>{
		state.categories = categorie
	},
	SET_GAUGE: (state, gauge)=>{
		state.gauge = gauge
	},
	SET_DATA_GRAPH_1: (state, data_graph)=>{
		state.graph_1 = data_graph
	},
	SET_DATA_GRAPH_2: (state, data_graph)=>{
		state.graph_2 = data_graph
	},
	SET_DATA_GRAPH_3: (state, data_graph)=>{
		state.graph_3 = data_graph
	},
	SET_DATA_GRAPH_4: (state, data_graph)=>{
		state.graph_4 = data_graph
		state.graph_5 = ca_charge(state.graph_1,state.graph_3)
		state.graph_6 = ca_charge(state.graph_2,state.graph_4)
		state.graph_7 = ca_charge(state.graph_1,state.graph_3).concat(ca_charge(state.graph_1,state.graph_3,"Budgeté"))
		state.graph_8 = ca_charge(state.graph_2,state.graph_4).concat(state.graph_7)
		state.graph_9 = ca_charge(state.graph_1,state.graph_3,"Budgeté")
		state.graph_10 = ca_charge(state.graph_2,state.graph_4,"Budgeté","Cumulé")
	},
	SET_DATA_GRAPH_CUMUL: (state, data_graph)=>{
		state.graph_cumul = data_graph
	},
	SET_DATA_GRAPH_TRESORERIE: (state, data_graph)=>{
		state.tresorerie = data_graph
	},
	SET_DATA_GRAPH_TOP: (state, data_graph)=>{
		state.top = data_graph
	},
	SET_DATA_GRAPH_ANALYSE: (state, data_graph)=>{
		state.analyse = data_graph
	},
}

const getters = {
	CATEGORIE: state => {
		return state.categories
	},
	SERIE: state => {
		return state.series
	},
	GAUGE: state => {
		return state.gauge
	},
	DATA_GRAPH_1: state => {
		return state.graph_1
	},
	DATA_GRAPH_2: state => {
		return state.graph_2
	},
	DATA_GRAPH_3: state => {
		return state.graph_3
	},
	DATA_GRAPH_4: state => {
		return state.graph_4
	},
	DATA_GRAPH_5: state => {
		return state.graph_5
	},
	DATA_GRAPH_6: state => {
		return state.graph_6
	},
	DATA_GRAPH_7: state => {
		return state.graph_7
	},
	DATA_GRAPH_8: state => {
		return state.graph_8
	},
	DATA_GRAPH_9: state => {
		return state.graph_9
	},
	DATA_GRAPH_10: state => {
		return state.graph_10
	},
	DATA_GRAPH_TRESORERIE: state => {
		return state.tresorerie
	},
	DATA_GRAPH_TOP: state => {
		return state.top
	},
	DATA_GRAPH_ANALYSE: state => {
		return state.analyse
	},
}

const actions = {

	GET_DATA_GRAPH : async({commit,dispatch},data)=>{

		commit('RESET_STATE')
		
		dispatch('notificationStoreIndex/addNotification', {
			type: 'loading',
			message: "Chargement des données pour le traitement des graphes et des gauges"
		}, { root: true });


		await Api().get("operations/data_synthese/"+data.id_exercice_cp+"/"+data.id_societe+"/"+data.cloture)
		.then(response=>{
			commit("SET_GAUGE",response.data.data)

			dispatch('notificationStoreIndex/addNotification', {
				type: 'success',
				message: "Les données des gauges ont été mise a jour"
			}, { root: true });
		})
		.catch(function (error){
			dispatch('CATCH_ERROR',error.response,"Probleme survenue lors de la mise a jour des gauges.")
		})

		await Api().get("operations/data_graph/"+data.id_exercice_cp+"/"+data.id_societe+"/vt"+'/'+data.cloture)
		.then(response=>{
			commit("SET_DATA_GRAPH_1",getJsonArray(response.data.data))
		})

		await Api().get("operations/data_graph_cumul/"+data.id_exercice_cp+"/"+data.id_societe+"/vt"+'/'+data.cloture)
		.then(response=>{
			commit("SET_DATA_GRAPH_2",getJsonArray(response.data.data))
		})

		await Api().get("operations/data_graph/"+data.id_exercice_cp+"/"+data.id_societe+"/ah"+'/'+data.cloture)
		.then(response=>{
			commit("SET_DATA_GRAPH_3",getJsonArray(response.data.data))
		})

		await Api().get("operations/data_graph_cumul/"+data.id_exercice_cp+"/"+data.id_societe+"/ah"+'/'+data.cloture)
		.then(response=>{
			commit("SET_DATA_GRAPH_4",getJsonArray(response.data.data))
		})

		await Api().get("operations/data_graph_tresorerie/"+data.id_exercice_cp+"/"+data.id_societe+'/'+data.cloture)
		.then(response=>{
			commit("SET_DATA_GRAPH_TRESORERIE",getJsonArray(response.data.data))
		})

		await Api().get("operations/data_graph_topCharge/"+data.id_exercice_cp+"/"+data.id_societe+"/10/"+data.cloture)
		.then(response=>{
			commit("SET_DATA_GRAPH_TOP",response.data.data)
		})


		await Api().get("operations/data_graph_topCharge/"+data.id_exercice_cp+"/"+data.id_societe+"/5/"+data.cloture)
		.then(response=>{
			commit("SET_DATA_GRAPH_ANALYSE",response.data.data)
		})

		dispatch('notificationStoreIndex/addNotification', {
			type: 'success',
			message: "Les données des graphes ont été mise a jour"
		}, { root: true });

	},
}

export default {
	state,
	mutations,
	getters,
	actions
}