import axios from 'axios'

export default() => {
  return axios.create({
    baseURL: 'http://api.freeboss.fr/api',
    withCredentials: false,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
      'Authorization': "Bearer "+localStorage.getItem("token"),
    }
  })
}