<template>
	<div class="notifications-list">
		<notification-message
			v-for="popup in popups"
			:key="popup.id"
			:popup="popup"
		/>
	</div>
</template>

<script>
import NotificationMessage from "./NotificationMessage";
import { mapGetters } from "vuex";

export default {
	components: {
		NotificationMessage
	},

	computed: mapGetters({popups:"notificationStoreIndex/POPUPS"})
};
</script>

<style>
.notifications-list {
	position: fixed;
	bottom: 0;
	right: 0;
	margin-right: 15px;
	z-index: 2;
}
</style>
