import { mount } from '@vue/test-utils'
import setting from '../views/index.vue'
import imported from '../views/index.vue'
import history from '../views/index.vue'
import comptable from '../views/index.vue'

describe('--------------------  Setting  -----------------------', () => {

	it('Setting page', async () => {
		const wrapper = mount(setting,{
			stubs: ['router-link']
		})

		expect(wrapper.vm).toBeTruthy()
	})

	it('Importation page', async () => {
		const wrapper = mount(imported,{
			stubs: ['router-link']
		})

		expect(wrapper.vm).toBeTruthy()
	})
		
	it('History page', async () => {
		const wrapper = mount(history,{
			stubs: ['router-link'],
			propsData:{
				societe: 1,
				exercice_comptable: 1,
				date_cloture: "2018-2-9"
			}
		})

		expect(wrapper.vm).toBeTruthy()
	})
		
	it('Comptable page', async () => {
		const wrapper = mount(comptable,{
			stubs: ['router-link'],
		})

		expect(wrapper.vm).toBeTruthy()
	})
		
})
