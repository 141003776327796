
	
	import Highcharts from "highcharts"
	import hcMore from "highcharts/highcharts-more"
	hcMore(Highcharts)

	export default{
		
		name: "hightchartsVue",
		props: {
			title: {
				type: String,
				default: ""
			},
			subtitle: {
				type: String,
			},
			chart:{
				type: String,
				required: true,
			},
			min: {
				type: Number,
			},
			max: {
				type: Number,
			},
			plotBands: {
				type: Object
			},
			tooltip:{
				type: String,
			},
			series:{
				type: Array,
				default(){return []}
			},
			categories:{
				type: Array,
				default(){return []}
			},
		},
		methods: {
			checkProp(){
				return this.chart != []
			},
			getRandomData(size,min,max) {
				var data = []
				for(var i=0;i<size;i++){
					data.push(Math.round(Math.random() * (max-min)))
				}
				return data
			},
			getColumnDataToSerial(series) {
				if(series.length==0){
					return [{name:"Data is empty",y:1}]
				}
				else{
					var serie = []
					var result = 0
					for(var i=0; i<series.length; i++){
						if(series[i].name==""){
							return Object.entries(series[i].data)
						}
						else{
							for(var j=0;j<series[i].data.length;j++){
								for(var val in series[i].data[j]){
									result += series[i].data[j][val]
								}
								serie.push({name:series[i].name,y:result})
								result=0
							}
						}
					}
					return serie
				}
			},
			getSplineDataToSerial(series) {
				var serie = JSON.parse(JSON.stringify(series))
				var data_translate = []

				for(var i=0;i<serie.length;i++){
					for(var j=0;j<serie[i].data.length;j++){
						data_translate = data_translate.concat(Object.values(serie[i].data[j]))
					}
					serie[i].data = data_translate
					data_translate= []
				}

				return serie
			},
			getCategorieToSerial(series) {
				var data_translate = []
				if(series[0] == undefined)
					return []
				for(var i=0;i<series.length;i++)
					for(var j=0;j<series[i].data.length;j++){
						if(!data_translate.includes(Object.keys(series[i].data[j]))){
							data_translate = data_translate.concat(Object.keys(series[i].data[j]))
						}
					}
				return data_translate
			},
			getChartOption(){
				var ChartOption = null
				switch(this.chart){
					
					case "gauge":
						ChartOption = {
							chart: {
								type: this.chart,
								height: 245
							},
							title: {
								text: this.title
							},

							pane: {
								startAngle: -150,
								endAngle: 150,
							},

							// the value axis
							yAxis: {
								min: this.min,
								max: this.max,
								labels: {
									step: 2,
									rotation: "auto"
								},
								plotBands: [
									{
										from: 0,
										to: 120,
										color: "#DF5353" // red
									},
									{
										from: 120,
										to: 160,
										color: "#DDDF0D" // yellow
									},
									{
										from: 160,
										to: 200,
										color: "#55BF3B" // green
									},
								]
							},
							series: [
								{
									name: "Previsionnel",
									data: [this.series[0]],
								},
								{
									name: "Réél",
									data: [this.series[1]],
								},
							]
						};
						break;

					case "spline":
						ChartOption = {
							chart: {
								height: 350,
								type: this.chart,
							},
							tooltip: {
								shared: true,
							},
							series: this.getSplineDataToSerial(this.series),
							title: {
								text: this.title
							},
							subtitle: {
								text: this.subtitle
							},
							xAxis: {
								categories: this.getCategorieToSerial(this.series)
							},
							yAxis: {
								title: {
									text: 'Montant'
								}
							},
						}
						break;

					case "pie":
						ChartOption = {
							chart: {
								type: 'pie'
							},
							title: {
								text: this.title
							},
							tooltip: {
								pointFormat: '{series.name}: <b>{point.y}</b>'
							},
							accessibility: {
								point: {
									valueSuffix: '%'
								}
							},
							plotOptions: {
								pie: {
									allowPointSelect: true,
									cursor: 'pointer',
									dataLabels: {
										enabled: true,
										format: '<b>{point.name}</b>: {point.percentage:.2f} %',
										connectorColor: 'silver'
									},
									showInLegend: true
								}
							},
							series: [
								{
									name: 'Montant',
									colorByPoint: true,
									data: this.getColumnDataToSerial(this.series)
								}
							]
						}
						break;

					case "column":
						ChartOption = {
							chart: {
								height: 350,
								type: this.chart,
							},
							tooltip: {
								pointFormat: '<b> {point.y:.0f} €</b>'
							},
							plotOptions: {
								pie: {
									allowPointSelect: true,
									cursor: 'pointer',
									dataLabels: {
										enabled: true,
										format: '<b>{point.name}</b>: {point.percentage:.1f} %',
										connectorColor: 'silver'
									},
									showInLegend: true
								}
							},
							xAxis: {
								type: 'category',
								labels: {
									rotation: -45,
									style: {
										fontSize: '13px',
										fontFamily: 'Verdana, sans-serif'
									}
								}
							},
							yAxis: {
								min: 0,
								title: {
									text: 'Montant'
								},            
								labels: {
									overflow: 'justify',
									formatter: function () {
										return this.value + ' €';
									}	
								}				
							},
							series: [{
								name: 'Palmarès des charges annuelles',
								data: this.getColumnDataToSerial(this.series),
								dataLabels: {
									enabled: true,
									rotation: -90,
									color: '#FFFFFF',
									align: 'right',
									style: {
										fontSize: '13px',
										fontFamily: 'Verdana, sans-serif'
									}
								}
							}],
						}
						break;

				}

				return ChartOption
			},
		},
	}
