<template>
	<div :class="typeClass" class="alert alert-dismissible" role="alert">
		<button type="button" class="close" data-dismiss="alert" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
		<div class="d-flex align-items-center">
			<div class="mr-2">
				<i class="fa fa-2x" :class="fontClass"></i>
			</div>
			<strong>{{ popup.message }}</strong>
		</div>
	</div>
</template>

<style type="text/css" scoped="">
	.alert-loading{
		background-color: #cce5ff;
	}
</style>

<script>
import { mapActions } from "vuex";

export default {
	name: "NotificationPopup",
	props: ["popup"],

	data() {
		return {
			timeout: null,
		};
	},

	computed: {
		typeClass() {
			return `alert-${this.popup.type}`;
		},
		fontClass(){
			let result = 'fa-'
			switch(this.popup.type){
				case 'success':
					result+="check-circle"
				break;
				case 'warning':
					result+="exclamation-circle"
				break;
				case 'danger':
					result+="times-circle"
				break;
				case 'loading':
					result+="spinner"
				break;
			}
			return result
		}
	},

	created() {
		this.timeout = setTimeout(() => {
			this.$store.dispatch("notificationStoreIndex/removePopup",this.popup);
		}, 5000);
	},

	beforeDestroy() {
		clearTimeout(this.timeout);
	},

	methods: mapActions(["notificationStoreIndexremovePopup"])
};
</script>