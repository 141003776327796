import { mount } from '@vue/test-utils'
import HIGHCHART_GAUGE from '../components/highchartsVue/index.vue'

describe('--------------------  HIGHCHART_GAUGE GAUGE  -----------------------', () => {
	
	describe('--------------------  HIGHCHART_GAUGE GAUGE  -----------------------', () => {
		
		const data_graph = {
			"ca":[0,242093.39000000007],
			"DATA_GRAPH_1":[{"name":"previsionnel","data":[{"1-2018":0,"2-2018":0,"3-2018":0,"4-2018":0,"5-2018":0,"6-2018":0,"7-2018":0,"8-2018":0,"9-2018":0,"10-2018":0,"11-2018":0,"12-2018":0}]},{"name":"réel","data":[{"1-2018":3971.83,"2-2018":7285.65,"3-2018":5312.01,"4-2018":9300.83,"5-2018":16643.02,"6-2018":26503.660000000003,"7-2018":20943.33,"8-2018":25107.370000000003,"9-2018":33092,"10-2018":18383.03,"11-2018":21108.03,"12-2018":54442.63}]},{"name":"Budgeté","data":[{"1-2018":0,"2-2018":0,"3-2018":0,"4-2018":0,"5-2018":0,"6-2018":0,"7-2018":0,"8-2018":0,"9-2018":0,"10-2018":0,"11-2018":0,"12-2018":0}]}],
			"DATA_GRAPH_TRESORERIE":[{"name":"debit","data":[{"1-2018":8818.75,"2-2018":10093.25,"3-2018":25190.18,"4-2018":19255.370000000003,"5-2018":22869.7,"6-2018":20690.93,"7-2018":29519.95,"8-2018":22956.249999999996,"9-2018":12595.01,"10-2018":24888.229999999996,"11-2018":11203.25,"12-2018":37974}]},{"name":"credit","data":[{"1-2018":9158.349999999999,"2-2018":8555.49,"3-2018":23737.02,"4-2018":19205.199999999997,"5-2018":24977.7,"6-2018":20997.480000000007,"7-2018":25180.820000000007,"8-2018":23777.980000000003,"9-2018":16707.260000000002,"10-2018":18643.700000000004,"11-2018":13427.6,"12-2018":39181.229999999996}]},{"name":"solde","data":[{"1-2018":339.59999999999854,"2-2018":-1537.7600000000002,"3-2018":-1453.1599999999999,"4-2018":-50.17000000000553,"5-2018":2108,"6-2018":306.55000000000655,"7-2018":-4339.129999999994,"8-2018":821.7300000000068,"9-2018":4112.250000000002,"10-2018":-6244.529999999992,"11-2018":2224.3500000000004,"12-2018":1207.229999999996}]}],
		}

		let wrapper

		it('Dashboard gauge chart vue instance', async () => {
			wrapper = mount(HIGHCHART_GAUGE,{
				propsData:{
					chart: "gauge",
					min: 0,
					max: 400,
					series: data_graph.ca
				}
			})

			expect(wrapper.vm).toBeTruthy()
		})

	})


	describe('--------------------  HIGHCHART_GAUGE SPLINE  -----------------------', () => {
		
		const data_graph = {
			"ca":[0,242093.39000000007],
			"DATA_GRAPH_1":[{"name":"previsionnel","data":[{"1-2018":0,"2-2018":0,"3-2018":0,"4-2018":0,"5-2018":0,"6-2018":0,"7-2018":0,"8-2018":0,"9-2018":0,"10-2018":0,"11-2018":0,"12-2018":0}]},{"name":"réel","data":[{"1-2018":3971.83,"2-2018":7285.65,"3-2018":5312.01,"4-2018":9300.83,"5-2018":16643.02,"6-2018":26503.660000000003,"7-2018":20943.33,"8-2018":25107.370000000003,"9-2018":33092,"10-2018":18383.03,"11-2018":21108.03,"12-2018":54442.63}]},{"name":"Budgeté","data":[{"1-2018":0,"2-2018":0,"3-2018":0,"4-2018":0,"5-2018":0,"6-2018":0,"7-2018":0,"8-2018":0,"9-2018":0,"10-2018":0,"11-2018":0,"12-2018":0}]}],
			"DATA_GRAPH_TRESORERIE":[{"name":"debit","data":[{"1-2018":8818.75,"2-2018":10093.25,"3-2018":25190.18,"4-2018":19255.370000000003,"5-2018":22869.7,"6-2018":20690.93,"7-2018":29519.95,"8-2018":22956.249999999996,"9-2018":12595.01,"10-2018":24888.229999999996,"11-2018":11203.25,"12-2018":37974}]},{"name":"credit","data":[{"1-2018":9158.349999999999,"2-2018":8555.49,"3-2018":23737.02,"4-2018":19205.199999999997,"5-2018":24977.7,"6-2018":20997.480000000007,"7-2018":25180.820000000007,"8-2018":23777.980000000003,"9-2018":16707.260000000002,"10-2018":18643.700000000004,"11-2018":13427.6,"12-2018":39181.229999999996}]},{"name":"solde","data":[{"1-2018":339.59999999999854,"2-2018":-1537.7600000000002,"3-2018":-1453.1599999999999,"4-2018":-50.17000000000553,"5-2018":2108,"6-2018":306.55000000000655,"7-2018":-4339.129999999994,"8-2018":821.7300000000068,"9-2018":4112.250000000002,"10-2018":-6244.529999999992,"11-2018":2224.3500000000004,"12-2018":1207.229999999996}]}],
		}

		let wrapper

		it('Dashboard spline chart vue instance', async () => {
			wrapper = mount(HIGHCHART_GAUGE,{
				propsData:{
					chart: "spline",
					series: data_graph.DATA_GRAPH_1
				}
			})

			expect(wrapper.vm).toBeTruthy()
		})
		
	})


	describe('--------------------  HIGHCHART_GAUGE column  -----------------------', () => {
		
		const data_graph = {
			"ca":[0,242093.39000000007],
			"DATA_GRAPH_1":[{"name":"previsionnel","data":[{"1-2018":0,"2-2018":0,"3-2018":0,"4-2018":0,"5-2018":0,"6-2018":0,"7-2018":0,"8-2018":0,"9-2018":0,"10-2018":0,"11-2018":0,"12-2018":0}]},{"name":"réel","data":[{"1-2018":3971.83,"2-2018":7285.65,"3-2018":5312.01,"4-2018":9300.83,"5-2018":16643.02,"6-2018":26503.660000000003,"7-2018":20943.33,"8-2018":25107.370000000003,"9-2018":33092,"10-2018":18383.03,"11-2018":21108.03,"12-2018":54442.63}]},{"name":"Budgeté","data":[{"1-2018":0,"2-2018":0,"3-2018":0,"4-2018":0,"5-2018":0,"6-2018":0,"7-2018":0,"8-2018":0,"9-2018":0,"10-2018":0,"11-2018":0,"12-2018":0}]}],
			"DATA_GRAPH_TRESORERIE":[{"name":"debit","data":[{"1-2018":8818.75,"2-2018":10093.25,"3-2018":25190.18,"4-2018":19255.370000000003,"5-2018":22869.7,"6-2018":20690.93,"7-2018":29519.95,"8-2018":22956.249999999996,"9-2018":12595.01,"10-2018":24888.229999999996,"11-2018":11203.25,"12-2018":37974}]},{"name":"credit","data":[{"1-2018":9158.349999999999,"2-2018":8555.49,"3-2018":23737.02,"4-2018":19205.199999999997,"5-2018":24977.7,"6-2018":20997.480000000007,"7-2018":25180.820000000007,"8-2018":23777.980000000003,"9-2018":16707.260000000002,"10-2018":18643.700000000004,"11-2018":13427.6,"12-2018":39181.229999999996}]},{"name":"solde","data":[{"1-2018":339.59999999999854,"2-2018":-1537.7600000000002,"3-2018":-1453.1599999999999,"4-2018":-50.17000000000553,"5-2018":2108,"6-2018":306.55000000000655,"7-2018":-4339.129999999994,"8-2018":821.7300000000068,"9-2018":4112.250000000002,"10-2018":-6244.529999999992,"11-2018":2224.3500000000004,"12-2018":1207.229999999996}]}],
		}

		let wrapper

		it('Dashboard pie chart vue instance', async () => {
			wrapper = mount(HIGHCHART_GAUGE,{
				propsData:{
					chart: "gauge",
					series: data_graph.DATA_GRAPH_TRESORERIE
				}
			})

			expect(wrapper.vm).toBeTruthy()
		})
		
	})

})
