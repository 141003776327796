import Api from '@/components/API'

// import moment from 'moment'

const state = {
	events: []
}

const	mutations = {
	AGENDA: (state, event)=>{
		state.events = event
	},
}

const getters = {
	EVENT: state => {
		return state.events
	},
}

const actions = {
	ADD_EVENT: ({dispatch},event) => {
		var form_data = new FormData();
		for ( var key in event ) {
			form_data.append(key, event[key]);
		}
		Api().post('/agenda',form_data)
		.then(()=>{
			dispatch('notificationStoreIndex/addNotification', {
				type: 'success',
				message: "L'evenement "+event.title+" a été ajouté avec success",
			}, { root: true })
			dispatch("AGENDA",event.societe_id)
		})
	},
	UPDATE_EVENT: ({dispatch},event) => {
		const form = require('querystring')
		Api().put("/agenda/"+event.id,form.stringify(event))
		.then(()=>{
			dispatch('notificationStoreIndex/addNotification', {
				type: 'success',
				message: "L'evenement "+event.title+" a été mis a jour avec success",
			}, { root: true })
			dispatch("AGENDA")
		})
	},
	DELETE_EVENT: ({dispatch},event) => {
		Api().delete("agenda/"+event)
		.then(response=>{
			dispatch("AGENDA",response.data.data)
			dispatch('notificationStoreIndex/addNotification', {
				type: 'success',
				message: "L'evenement "+event.title+" a été supprimé avec success",
			}, { root: true })
		})
	},
	AGENDA: ({commit},societe)=>{
		Api().get("/agenda-societe/"+societe)
		.then(response=>{
			commit("AGENDA",response.data.data)
		})
	},
}

export default {
	state,
	mutations,
	getters,
	actions
}