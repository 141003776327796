const Blog = () => import('@/modules/blog/views/index.vue')
const ShowBlog = () => import('@/modules/blog/views/show.vue')

const Dash = () => import('@/views/dash.vue')

const routes = [
	{
		path: 'blog',
		component: Dash,
		children:[
			{
				path: '',
				name: 'Blog',
				component: Blog,
			},
			{
				path: ':id',
				name: 'ShowBlog',
				component: ShowBlog,
			},
		]
	},
]

export default routes
