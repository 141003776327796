import store from '../store'

describe('mutations', () => {

  const state = {
    events: []
  }
  
  it('renders index.msg when passed', () => {

    const events = [
      {
        "id": 2,
        "title": "Test technique",
        "allDay": false,
        "start": "2020-11-09 12:00:00",
        "end": "2020-11-18 19:00:00",
        "color": "#ff8000"
      },
      {
        "id": 3,
        "title": "Teazast technique",
        "allDay": false,
        "start": "2020-11-09 12:00:00",
        "end": "2020-11-28 19:00:00",
        "color": "#ff8000"
      }
    ]

    store.mutations.AGENDA(state, events)
    expect(state.events).toBe(events)
  })
  
})

