
const Auth = () => import('@/modules/auth/views/auth.vue')
// const Register = () => import('@/modules/auth/views/register.vue')
const Dash = () => import('@/views/dash.vue')

const routes = [
	{
		path: '/auth',
		redirect: 'login',
		component: Dash,
		meta:{
			requiresNotAuth: true
		},
		children:[
			{
				path: 'login',
				name: 'Login',
				component: Auth,
			},
			// {
			// 	path: 'register',
			// 	name: 'Register',
			// 	component: Register,
			// },
		]
	}
]

export default routes